import React, { Component } from "react";

import RegisterFormEdit from "../../components/public/RegisterFormEdit";


const RegisterUpdate = () => {

  return (
      <div>

          <RegisterFormEdit/>
      </div>
  );
};

export default RegisterUpdate;
