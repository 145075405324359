import React, { Component } from "react";

import RegisterForm from "../../components/public/RegisterForm";


const Register = () => {

  return (
      <div>

          <RegisterForm/>
      </div>
  );
};

export default Register;
