import React from 'react';
import Erreur404 from '../components/public/Error404';

const Error = () => {
    return (
        <div>
            <Erreur404/>
        </div>
    );
};

export default Error;